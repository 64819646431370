import JoiValidator from 'joi';

export const Joi = JoiValidator.extend((joi) => ({
  type: 'string',
  base: joi.string(),
  coerce: {
    from: 'number',
    method: (value) => ({ value: (!isNaN(parseFloat(value)) && isFinite(value)) ? value.toString() : value }),
  }
}));
