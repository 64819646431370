import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Role } from '@app/common/model/auth/role';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent {
  readonly Role = Role;
  @Input() title!: string;

  @Input() roles: Role[] = [Role.admin];

  @Output() contentInit = new EventEmitter<void>();
  @Output() rolesLoaded = new EventEmitter<Role[]>();
}
