import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, lastValueFrom, Observable, tap } from 'rxjs';
import { environment } from '@environment';
import { NotificationHandler } from '@app/common/helper/notification.handler';
import { DataUtil } from '@app/helper';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  private http = inject(HttpClient);

  @Input() acceptTypes: string[] = [];
  @Input() multiSelect = false;
  @Input() showSelected = true;
  @Input() allowUrl = false;

  @Output() fileSelected = new EventEmitter<File[]>();

  get fileTypes(): string {
    return this.acceptTypes.map(e => `.${e}`).join(',')
  }

  readonly urlProcessing$ = new BehaviorSubject(false);
  files: File[] = [];

  dropFile(files: File[]) {
    this.handleFileInternal(files);
  }

  selectFile(event: Event) {
    if (event.target && 'files' in event.target) {
      this.handleFileInternal(event.target['files'] as File[] || [], event);
    }
  }

  async handleUrlChange(imageUrl: string): Promise<void> {
    if (!imageUrl) return;

    const ext = DataUtil.getFileExtension(imageUrl);

    if (!this.acceptTypes?.includes(ext?.toLocaleLowerCase())) {
      return NotificationHandler.handleError(`File ${imageUrl} has unsupported image format. Supported formats: ${this.acceptTypes}`);
    }

    const url = `${environment.api.host}/images/fetch?url=${imageUrl}`;

    const blob = await lastValueFrom(this.fetchImage(url));
    this.handleFileInternal(
      [new File([blob], DataUtil.getFileName(imageUrl) || 'noname.jpg', { type: 'image/jpeg' })]
    );
  }

  private fetchImage(url: string): Observable<Blob> {
    this.urlProcessing$.next(true);
    return this.http.get(url, { responseType: 'blob' }).pipe(
      tap(() => this.urlProcessing$.next(false)),
      catchError(error => {
        this.urlProcessing$.next(false);
        NotificationHandler.handleError('Upload failed: The URL you provided does not support uploading. Please provide a valid URL.');
        console.error(error);
        return EMPTY;
      }),
    );
  }

  private clearFileInput(event: Event) {
    if (event?.target as HTMLInputElement) {
      (event.target as HTMLInputElement).value = '';
    }
  }

  private handleFileInternal(files: File[], event?: Event) {
    for (const file of files) {
      if (this.acceptTypes.length) {
        const formtAllowed = this.acceptTypes.some(type => file.name.endsWith(type));
        if (!formtAllowed) {
          NotificationHandler.handleError(`File ${file.name} has unsupported image format. Supported formats: ${this.acceptTypes}`);
          break;
        }
      }

      if (!this.multiSelect && this.files.length >= 1) {
        this.files = [];
        this.files.push(file);
      } else {
        this.files.push(file);
      }

      this.fileSelected.emit(this.files);
    }
    if (event) this.clearFileInput(event);
  }

  deleteAttachment(index: number) {
    this.files.splice(index, 1);
    this.fileSelected.emit(this.files);
  }
}
