<div class="page-container">
  <div class="page">
    <div class="page__header">
      <span class="page__title">{{ title }}</span>
      <ng-content select="[header]"></ng-content>
    </div>

    <permission-checker [roles]="roles"
                        (contentInit)="contentInit.emit()"
                        (rolesLoaded)="rolesLoaded.emit()">
      <div class="page__content">
        <ng-content></ng-content>
      </div>
      <div class="page__footer">
        <ng-content select="[footer]"></ng-content>
      </div>
    </permission-checker>
  </div>
  <div>
