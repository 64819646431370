<mat-form-field class="full-width"
                [style.margin-bottom]="!showHint ? '-1.25em' : 'auto'">
  <mat-label>{{label}}</mat-label>
  <mat-select [disabled]="disabled"
              (selectionChange)="itemSelected($event)"
              [compareWith]="compareWith"
              [required]="required"
              [value]="selectedItem">
    <mat-option *ngFor="let item of items"
                [value]="item"
                [disabled]="checkItemDisabled(item)">{{displayWith(item)}}</mat-option>
  </mat-select>
  <button matButton
          matSuffix
          mat-icon-button
          *ngIf="clearable && selectedItem"
          (click)="$event.stopPropagation();selectedItem = undefined;selected.emit(undefined)"
          matTooltip="Clear">
    <mat-icon>close</mat-icon>
  </button>
  <mat-spinner *ngIf="!items"
               diameter="15"
               color="primary"
               matSuffix></mat-spinner>
</mat-form-field>
